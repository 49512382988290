import "./SafetyPayInfo.css"

export default function SafetyPayInfo() {
  return (
    <div className="header-container">
      <div className="pci-image"></div>
      <div style={{ gridColumn: "2" }}>
        <h4>Incident Report</h4>
      </div>
      <div className="safety-pay-info-container">
        <h6>Deduct safety pay?</h6>
        <h6>Mike P: Y N</h6>
        <h6>Mark P: Y N</h6>
      </div>
    </div>
  )
}

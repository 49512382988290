import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { types } from "../../utils"
import { Button, Typography } from "@mui/material"
import axios from "axios"
import Redirect from "../Redirect"
import Loading from "../Loading/Loading"
import "./EditClaimForm.css"
import DatePicker from "../DatePicker"

export default function EditClaimForm({ acquireToken }) {
  const [metaData, setMetaData] = useState({
    UUID: "",
    ReportType: "",
    IncidentDateTime: new Date(),
    ClaimantName: "",
    AffectedEmployeeName: "",
    AffectedEmployeeEmployeeNumber: "",
    JobNumber: "",
    IncidentDescription: "",
    IncidentCause: ""
  })
  const [buttonMessage, setButtonMessage] = useState("Save")
  const { state } = useLocation()
  const navigate = useNavigate()

  const handleFetchMetaDataAsync = async () => {
    const token = await acquireToken()
    const { data } = await axios.get(`api/get/${state}`, { headers: { Authorization: `Bearer ${token}` } })
    setMetaData({
      UUID: data.UUID,
      ReportType: data.ReportType,
      IncidentDateTime: data.IncidentDateTime,
      ClaimantName: data.ClaimantName,
      AffectedEmployeeName: data.AffectedEmployeeName,
      AffectedEmployeeEmployeeNumber: data.AffectedEmployeeEmployeeNumber,
      JobNumber: data.JobNumber,
      IncidentDescription: data.IncidentDescription,
      IncidentCause: data.IncidentCause
    })
  }

  useEffect(() => {
    handleFetchMetaDataAsync()
  }, [state])

  const handleUpdateMetaData = async (e) => {
    e.preventDefault()
    setButtonMessage("saving...")
    const token = await acquireToken()
    await axios
      .post(`api/updatemetadata/${state}`, metaData, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        navigate(`/${state}`)
        return res
      })
      .catch((err) => {
        console.error(err)
        setButtonMessage("Error...")
      })
  }

  return (
    <>
      <Redirect />
      <div className="menu-container">
        <Button
          type="submit"
          form="editMetadataForm"
          variant="contained"
          size="small"
          aria-label="small button group"
          sx={{ width: "75px" }}
        >
          {buttonMessage}
        </Button>
        <Button
          variant="contained"
          size="small"
          color="error"
          aria-label="small button group"
          onClick={() => navigate(`/${state}`)}
          sx={{ width: "75px" }}
        >
          Cancel
        </Button>
      </div>
      <div className="details-container">
        {(metaData.ReportType && (
          <form id="editMetadataForm" onSubmit={(e) => handleUpdateMetaData(e)}>
            <div className="edit-meta-form">
              <Typography variant="h5" sx={{ marginLeft: "2%", color: "white" }}>
                Edit information for claim # {metaData.UUID}
              </Typography>
              <DatePicker set={setMetaData} dateTime={metaData.IncidentDateTime} />
              <label htmlFor="claim-type" style={{ color: "white" }}>
                Claim Type:
              </label>
              <select
                id="claim-type"
                value={metaData.ReportType}
                style={{ width: "150px" }}
                onChange={({ target }) => setMetaData((curr) => ({ ...curr, ReportType: target.value }))}
              >
                {types.map((type) => (
                  <option value={type}>{type}</option>
                ))}
              </select>
              <label htmlFor="jobNumber" style={{ color: "white" }}>
                Job Number:
              </label>
              <input
                id="jobNumber"
                type="text"
                value={metaData.JobNumber}
                maxLength={8}
                style={{ width: "150px" }}
                onChange={({ target }) => setMetaData((curr) => ({ ...curr, JobNumber: target.value }))}
              />
              <label htmlFor="claimant" style={{ color: "white" }}>
                Claimant:
              </label>
              <input
                id="claimant"
                type="text"
                maxLength={256}
                value={metaData.ClaimantName}
                style={{ width: "150px" }}
                onChange={({ target }) => setMetaData((curr) => ({ ...curr, ClaimantName: target.value }))}
              />
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "left" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label htmlFor="affectedEmployeeName" style={{ color: "white" }}>
                    Affected Employee Name:
                  </label>
                  <input
                    id="affectedEmployeeName"
                    type="text"
                    maxLength={35}
                    value={metaData.AffectedEmployeeName}
                    style={{ width: "150px" }}
                    onChange={({ target }) => setMetaData((curr) => ({ ...curr, AffectedEmployeeName: target.value }))}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
                  <label htmlFor="affectedEmployeeNumber" style={{ color: "white" }}>
                    Affected Employee #:
                  </label>
                  <input
                    id="affectedEmployeeNumber"
                    type="text"
                    maxLength={4}
                    value={metaData.AffectedEmployeeEmployeeNumber}
                    style={{ width: "150px" }}
                    onChange={({ target }) =>
                      setMetaData((curr) => ({ ...curr, AffectedEmployeeEmployeeNumber: target.value }))
                    }
                  />
                </div>
              </div>
              <label htmlFor="description" style={{ color: "white" }}>
                Incident description:
              </label>
              <textarea
                id="description"
                rows="8"
                maxLength={2048}
                value={metaData.IncidentDescription}
                onChange={({ target }) => setMetaData((curr) => ({ ...curr, IncidentDescription: target.value }))}
              />
              <label htmlFor="cause" style={{ color: "white" }}>
                Incident cause:
              </label>
              <textarea
                id="cause"
                rows="8"
                maxLength={1024}
                value={metaData.IncidentCause}
                onChange={({ target }) => setMetaData((curr) => ({ ...curr, IncidentCause: target.value }))}
              />
            </div>
          </form>
        )) || <Loading />}
      </div>
    </>
  )
}

import axios from "axios"
import "./ClaimWitness.css"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { Box, Button, Card, CardContent, Typography } from "@mui/material"

export default function ClaimWitness({ witness, notes, acquireToken }) {
  const [codeSent, setCodeSent] = useState(false)
  const navigate = useNavigate()

  const accessCodeWillInvalidateExistingCode = async () => {
    const token = await acquireToken()
    const response = await axios.get(`api/will/invalidate/${witness.UUID}`, {
      headers: { ...axios.headers, Authorization: `Bearer ${token}` }
    })
    return response.data
  }

  const handleSendAccessCode = async () => {
    const willInvalidate = await accessCodeWillInvalidateExistingCode()
    if (willInvalidate) {
      if (
        !window.confirm("Sending this code will invalidate an existing acces code. Are you sure you want to continue?")
      ) {
        return
      }
    }
    const token = await acquireToken()
    await axios.post(`api/code/${witness.UUID}`, null, {
      headers: { ...axios.headers, Authorization: `Bearer ${token}` }
    })
    setCodeSent(true)
  }

  const handleViewWitnessReport = () => {
    navigate("/witnessreportdetails", { state: witness })
  }

  return (
    <Box sx={{ margin: "5%", zIndex: -1 }}>
      <Card variant="outlined" sx={{ boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h5">{witness.WitnessInfo.Name}</Typography>
          <Typography variant="h6" color="text.secondary">
            {witness.WitnessInfo.EmailAddress}
          </Typography>
          <Typography variant="h6">{witness.WitnessInfo.PhoneNumber}</Typography>
          <div>
            {(!witness.WitnessReport || !witness.WitnessReport.Completed) && (
              <Button
                variant="contained"
                color={codeSent ? "success" : "warning"}
                onClick={codeSent ? () => null : handleSendAccessCode}
              >
                {codeSent ? "Code Sent!" : "Send access code"}
              </Button>
            )}
            {witness.WitnessReport && witness.WitnessReport.GeneralInfo && (
              <Button variant="contained" color="success" onClick={handleViewWitnessReport}>
                View Report
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
    </Box>
  )
}

import { LogLevel } from "@azure/msal-browser"
import { DEBUG } from "./App"

export const Config = {
  auth: {
    clientId: "0a8516fd-4de1-4231-a57d-e63f3099b031",
    authority: "https://login.microsoftonline.com/90ed5fae-c958-47dd-85cd-9de88a748b21/",
    redirectUri: "/",
    postLogoutRedirectUri: "/"
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (!DEBUG || containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
          default:
            return
        }
      }
    }
  }
}

export const loginRequest = {
  scopes: ["api://516c77e8-2fb0-40c1-8d68-0f844575928e/User.Impersonate"]
}

export const logoutRequest = {
  mainWindowRedirectUri: "/"
}

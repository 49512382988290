import React from "react"
import ReactDOM from "react-dom/client"
import { EventType, PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { Config, loginRequest } from "./msalConfig"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import App from "./App"
import "./index.css"

const msalInstance = new PublicClientApplication(Config)
msalInstance.initialize().then(() => {
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0 &&
    msalInstance.getActiveAccount() !== null
  ) {
    msalInstance.setActiveAccount(msalInstance.getActiveAccount())
  }

  msalInstance.addEventCallback((e) => {
    if (e.eventType === EventType.LOGIN_SUCCESS && e.payload.account) {
      const account = e.payload.account
      msalInstance.setActiveAccount(account)
    }
  })
  msalInstance.handleRedirectPromise().then(handleResponse)
})

function handleResponse(response) {
  if (response !== null) {
    msalInstance.setActiveAccount(response.account)
  } else {
    const currentAccounts = msalInstance.getAllAccounts()
    if (currentAccounts.length === 0) {
      msalInstance.loginRedirect(loginRequest)
    } else if (currentAccounts.length > 0) {
      msalInstance.setActiveAccount(currentAccounts[0])
    }
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>
)

serviceWorkerRegistration.register()

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { useState } from "react"

export default function DeleteButton({ deleteReport }) {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleDelete = async () => {
    await deleteReport()
    handleClose()
  }
  const handleClose = () => setOpen(false)

  return (
    <>
      <Button
        variant="contained"
        color="error"
        aria-label="small button group"
        sx={{ width: "75px" }}
        onClick={handleOpen}
      >
        Delete
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">* Delete claim confirmation *</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are about to permanently delete this claim. Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete}>yes</Button>
          <Button onClick={handleClose} autoFocus>
            NO
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

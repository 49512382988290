import { prettyDate, prettyTime } from "../../../utils"

export default function GeneralInfo({ section }) {
  return (
    <div className="report-info-container">
      <h4>General information</h4>
      <div className="info-section">
        <table>
          <tbody>
            <tr>
              <td style={{ fontWeight: "bold" }}>Submitted by:</td>
              <td style={{ fontWeight: "bold" }}>{section.Reporter.Name}</td>
            </tr>
            <tr>
              <td>Incident date: </td>
              <td>{prettyDate(section.IncidentDatetime)}</td>
            </tr>
            <tr>
              <td>Incident time:</td>
              <td>{prettyTime(section.IncidentDatetime)}</td>
            </tr>
            <tr>
              <td>Job number: </td>
              <td>{section.JobNumber}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Employee involved:</td>
              <td style={{ fontWeight: "bold" }}>{section.AffectedEmployee.Name}</td>
            </tr>
            <tr>
              <td>Employee #</td>
              <td>{section.AffectedEmployee.EmployeeNumber}</td>
            </tr>
            <tr>
              <td>Supervisor:</td>
              <td>{section.Supervisor.Name}</td>
            </tr>
            {section.EmployeeDuties && (
              <tr>
                <td>Employee duties:</td>
                <td>{section.EmployeeDuties}</td>
              </tr>
            )}
            {section.VehicleNumber && (
              <tr>
                <td>Vehicle number:</td>
                <td>{section.VehicleNumber}</td>
              </tr>
            )}
            {section.EquipmentNumber && (
              <tr>
                <td>Equipment number:</td>
                <td>{section.EquipmentNumber}</td>
              </tr>
            )}
            <tr>
              <td>Reported to:</td>
              <td>{section.SupervisorReportedTo.Name}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default function HospitalInfo({ section }) {
  return (
    <div className="report-info-container">
      <h4>Hospital Information</h4>
      {(section && (
        <div className="info-section">
          <table>
            <tbody>
              {section.HospitalName && (
                <tr>
                  <td>Clinic Name:</td>
                  <td>{section.HospitalName}</td>
                </tr>
              )}
              {section.City && (
                <tr>
                  <td>City:</td>
                  <td>{section.City}</td>
                </tr>
              )}
              {section.State && (
                <tr>
                  <td>State:</td>
                  <td>{section.State}</td>
                </tr>
              )}
              {section.Zip && (
                <tr>
                  <td>Zip:</td>
                  <td>{section.Zip}</td>
                </tr>
              )}
              {section.Doctor && section.Doctor.Name && (
                <tr>
                  <td>Doctor's Name:</td>
                  <td>{section.Doctor.Name}</td>
                </tr>
              )}
              {section.Restrictions && (
                <tr>
                  <td>Restrictions:</td>
                  <td>{section.Restrictions}</td>
                </tr>
              )}
              <tr>
                <td>Drug Tested:</td>
                <td>{["Yes", "No"][+section.EmployeeDrugTested]}</td> {/* boolean-to-integer coersion */}
              </tr>
              {section.NoDrugTestComments && (
                <tr>
                  <td>Drug Test Comments:</td>
                  <td>{section.NoDrugTestComments}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )) || <h6>N/A</h6>}
    </div>
  )
}

import Witness from "./Witness"
import "./Witness.css"

export default function WitnessContainer({ witnesses, list }) {
  return (
    <div className="witnesses-container">
      <h4>Witnesses</h4>
      {witnesses.map((witness) => {
        return (
          <Witness
            info={witness}
            filed={list.some((e) => e.GeneralInfo.Reporter.name === witness.Name)}
            key={witness.UUID}
          />
        )
      })}
    </div>
  )
}

export default function DetailedInfo({ section }) {
  return (
    <div className="report-info-container">
      <h4>Detailed information</h4>
      <div className="info-section">
        <table>
          <tbody>
            <tr>
              <td style={{ fontWeight: "bold" }}>Incident description: </td>
              <td style={{ fontWeight: "bold" }}>{section.IncidentDescription}</td>
            </tr>
            {section.IncidentLocationDescription && (
              <tr>
                <td>Incident location: </td>
                <td>{section.IncidentLocationDescription}</td>
              </tr>
            )}
            {section.IncidentCause && (
              <tr>
                <td>Incident cause: </td>
                <td>{section.IncidentCause}</td>
              </tr>
            )}
            {section.PartyAtFault && (
              <tr>
                <td>Party at fault: </td>
                <td>{section.PartyAtFault}</td>
              </tr>
            )}
            {section.PossiblePreventionActions && (
              <tr>
                <td>Prevention actions: </td>
                <td>{section.PossiblePreventionActions}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

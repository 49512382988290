import { useLocation, useNavigate } from "react-router-dom"
import { PrimaryReport } from "../PrimaryReport/PrimaryReport"
import { useRef } from "react"
import { Button, Typography } from "@mui/material"
import ReactToPrint from "react-to-print"
import "./WitnessReportDetails.css"

export default function WitnessReportDetails() {
  //--- React hooks ---//
  const navigate = useNavigate()
  const reportRef = useRef()
  const location = useLocation()

  return (
    <div className="witness-report-details-container">
      <div className="menu-container">
        <Button variant="contained" size="small" onClick={() => navigate(`/${location.state.SafetyClaimUUID}`)}>
          Return
        </Button>
        <ReactToPrint
          trigger={() => (
            <Button variant="contained" size="small">
              Print
            </Button>
          )}
          content={() => reportRef.current}
        />
      </div>
      <div className="details-container">
        <Typography variant="h5" sx={{ marginLeft: "2%", color: "white" }}>
          Witness Report
        </Typography>
        {(location.state.WitnessReport.GeneralInfo && (
          <PrimaryReport report={location.state.WitnessReport} ref={reportRef} />
        )) || (
          <Typography variant="h5" sx={{ marginLeft: "2%", color: "white" }}>
            This witness hasn't filled out a witness report yet
          </Typography>
        )}
      </div>
    </div>
  )
}

export default function PoliceInfo({ section }) {
  return (
    <div className="report-info-container">
      <h4>Police Information</h4>
      {(section && (
        <div className="info-section">
          <table>
            <tbody>
              {section.RespondingDepartment && (
                <tr>
                  <td>Responding Dept:</td>
                  <td>{section.RespondingDepartment}</td>
                </tr>
              )}
              {section.PrimaryOfficer && section.PrimaryOfficer.Name && (
                <tr>
                  <td>Officer Name:</td>
                  <td>{section.PrimaryOfficer.Name}</td>
                </tr>
              )}
              {section.ReportNumber && (
                <tr>
                  <td>Report Number:</td>
                  <td>{section.ReportNumber}</td>
                </tr>
              )}
              {section.CitationNumber && (
                <tr>
                  <td>Citation Number:</td>
                  <td>{section.CitationNumber}</td>
                </tr>
              )}
              {section.CitationReason && (
                <tr>
                  <td>Citation Reason:</td>
                  <td>{section.CitationReason}</td>
                </tr>
              )}
              {section.CitationRecipient && section.CitationRecipient.Name && (
                <tr>
                  <td>Citation Recipient:</td>
                  <td>{section.CitationRecipient.Name}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )) || <h6>N/A</h6>}
    </div>
  )
}

export default function Witness({ info, filed }) {
  return (
    <div className="witness-container">
      <div className="witness-info-container">
        <table>
          <tbody>
            <tr>
              <td>Name: </td>
              <td>{info.Name}</td>
            </tr>
            <tr>
              <td>Email: </td>
              <td>{info.EmailAddress}</td>
            </tr>
            <tr>
              <td>Phone: </td>
              <td>{info.PhoneNumber}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

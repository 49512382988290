// --- Helper Functions ---//

export const prettyDate = (date) => {
  try {
    const [year, month, day] = date.split("T")[0].split("-")
    return `${month.replace(/^0/, "")}/${day.replace(/^0/, "")}/${year}`
  } catch (e) {
    return new Date().toLocaleDateString()
  }
}

export const prettyTime = (date) => {
  try {
    const [hours, minutes] = date.split("T")[1].split(":")
    const suffix = ["AM", "PM"][+(+hours >= 12)] // <--- JS is weird -❤️A.
    return `${hours === 12 ? 12 : hours % 12}:${minutes} ${suffix}`
  } catch (e) {
    return ""
  }
}

export const types = ["UtilityStrike", "Injury", "Employee", "Other"]

export const getOrientation = () => window.screen.orientation.type.split("-")[0]

export const getWindowAngle = () => window.screen.orientation.angle

export const isIPhone = () => window.navigator.userAgent.includes("iPhone")

export const stringToColor = (s) => {
  const hash = [...s].map((c) => c.charCodeAt()).reduce((h, v) => v + (h << 5) - h)
  return Array.from({ length: 3 }, (_, i) => i).reduce(
    (h, v) => h + ((hash >> (v * 8)) & 0xff).toString(16).padStart(2, "0").slice(-2),
    "#"
  )
}

export const makeAvatar = (s) => ({
  sx: { bgcolor: stringToColor(s) },
  children: `${s[0]}${(s.match(/(?<=\s)[A-Z]/g) || [" "])[0]}`
})

export const formatNote = (note) => {
  try {
    return (
      <h6
        style={{
          fontWeight: 800,
          color: "black",
          marginLeft: "10px"
        }}
      >{` - On ${prettyDate(note.CreationDate)} ${note.CreatedBy.replace(/^PCI\\/, "").replace(/@.+/g, "")} wrote: ${
        note.Text
      }`}</h6>
    )
  } catch {
    //---> In case something goes wrong in colorizing the note or the note is null
    return <h6>Couldn't resolve note</h6>
  }
}

export const userCanEditOrDeleteNote = (name1, name2) => new RegExp(name1).test(name2)

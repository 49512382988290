import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Button } from "@mui/material"
import "./EditNotesForm.css"
import NoteEditView from "./NoteEditView"
import axios from "axios"
import { useMsal } from "@azure/msal-react"

export default function EditNotesForm({ acquireToken }) {
  const { state } = useLocation()
  const user = useMsal().instance.getActiveAccount().username
  const [notes, setNotes] = useState(state.notes)
  const [newNote, setNewNote] = useState({
    CreatedBy: user,
    CreationDate: Date.now(),
    Id: crypto.randomUUID(),
    Text: ""
  })

  const navigate = useNavigate()

  const handleAddNote = async () => {
    if (!newNote.Text) return
    const token = await acquireToken()
    await axios.post(
      `/api/addnote/${state.uuid}`,
      { newNote: newNote.Text },
      {
        headers: { ...axios.headers, Authorization: `Bearer ${token}` }
      }
    )
    setNotes((curr) => [...curr, newNote])
    setNewNote({
      CreatedBy: user,
      CreationDate: Date.now(),
      Id: crypto.randomUUID(),
      Text: ""
    })
  }
  const handleNoteDelete = async (i, note) => {
    const token = await acquireToken()
    await axios.post(
      `/api/deletenote/${note.Id}`,
      {},
      { headers: { ...axios.headers, Authorization: `Bearer ${token}` } }
    )
    setNotes((curr) => curr.filter((n) => n.Id !== note.Id))
    return Promise.resolve()
  }

  return (
    <div className="edit-notes-container">
      <div className="menu-container">
        <Button
          variant="contained"
          size="small"
          aria-label="small button group"
          sx={{ width: "75px" }}
          onClick={() => {
            navigate(`/${state.uuid}`)
          }}
        >
          Done
        </Button>
        <Button
          variant="contained"
          size="small"
          color="error"
          aria-label="small button group"
          onClick={() => navigate(`/${state.uuid}`)}
          sx={{ width: "75px" }}
        >
          Cancel
        </Button>
      </div>
      <div className="new-note-container">
        <textarea
          id="new-note-text"
          rows="4"
          cols="50"
          value={newNote.Text}
          style={{ borderRadius: "5px" }}
          placeholder="enter a new note here"
          onChange={({ target }) => setNewNote((curr) => ({ ...curr, Text: target.value }))}
        />
        <button
          style={{
            borderRadius: "5px",
            marginLeft: "10px",
            width: "75px",
            backgroundColor: (newNote.Text && "#b9f6ca") || "#aaaaaa"
          }}
          disabled={!newNote.Text}
          onClick={handleAddNote}
        >
          {(newNote.Text && "Add Note") || "Enter note"}
        </button>
      </div>
      <div className="notes-container">
        {notes.map((note, i) => (
          <NoteEditView note={note} key={note.Id} index={i} del={handleNoteDelete} />
        ))}
      </div>
    </div>
  )
}

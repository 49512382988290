import { prettyDate, prettyTime } from "../../../utils"

export default function InjuryInfoSignature({ section, employee }) {
  return (
    <div className="report-info-container">
      <h4>Employee Injury Information Acknowledgement</h4>
      {(section && section.Signature && (
        <div className="info-section">
          <h6>
            Signed by {employee} on {prettyDate(section.Signature.CreationDate)} at{" "}
            {prettyTime(section.Signature.CreationDate)}
          </h6>
          <img src={`${section.Signature.Base64}`} alt="" style={{ width: "100px" }} />
        </div>
      )) || <h6>N/A</h6>}
    </div>
  )
}

import { useMsal } from "@azure/msal-react"
import { logoutRequest } from "../../../msalConfig"
import { Avatar } from "@mui/material"
import { makeAvatar } from "../../../utils"
import "./SignOutButton.css"

export default function SignOutButton() {
  const { instance } = useMsal()
  const account = instance.getActiveAccount()
  const handleLogout = async () => {
    await instance.logoutPopup({ ...logoutRequest })
  }

  return (
    <div className="user-container">
      <button id="signout-button" className="signout-button" onClick={handleLogout}>
        Sign Out
      </button>
      {account && <Avatar {...makeAvatar(account.name)} />}
    </div>
  )
}

import { useNavigate } from "react-router-dom"
import { Avatar, Card, CardContent, CardHeader, IconButton, Typography } from "@mui/material"
import { formatNote, prettyDate, prettyTime } from "../../utils"
import { green, red } from "@mui/material/colors"
import "./Claim.css"
import { DoneOutlined } from "@mui/icons-material"

export default function Claim({ claim }) {
  const navigate = useNavigate()

  const handleViewClaim = (e) => {
    navigate(`/${claim.UUID || claim.Id}`)
  }

  return (
    <div className="claim-container">
      <Card
        variant="outlined"
        sx={{ boxShadow: "-2px 2px 10px rgb(117, 35, 35)", padding: 5 }}
        onClick={(e) => handleViewClaim(e)}
      >
        {claim.StatusClosed && (
          <IconButton>
            <DoneOutlined sx={{ backgroundColor: green[400] }} />
          </IconButton>
        )}
        <CardHeader
          avatar={
            <Avatar sx={{ width: 70, bgcolor: red[500], fontSize: 12 }}>{`${
              claim.FilingString || claim.ReportType
            }`}</Avatar>
          }
          title={`${prettyDate(claim.IncidentDateTime)} ${claim.ClaimantName} ${claim.JobNumber}`}
          subheader={`Employee involved: ${claim.AffectedEmployeeName || "none"} ${
            claim.AffectedEmployeeEmployeeNumber || "none"
          }`}
        />
        <CardContent>
          <Typography>
            <div style={{ gridColumn: "2", textAlign: "center" }}>
              <h4>{claim.IncidentDescription}</h4>
              <h5>{claim.IncidentCause}</h5>
            </div>
          </Typography>
          {claim.Comments.length > 0 && claim.Comments.map((comment) => formatNote(comment))}
          <h6>Report filed on: {prettyDate(claim.CompletionDate) + "  @ " + prettyTime(claim.CompletionDate)}</h6>
        </CardContent>
      </Card>
    </div>
  )
}

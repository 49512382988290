import { useIsAuthenticated, useMsal } from "@azure/msal-react"
import SignOutButton from "./SignOutButton/SignOutButton"
import SignInButton from "./SignInButton/SignInButton"
import "./SignInSignOutButton.css"

export default function SignInSignOutButton() {
  const { inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  return (
    <div className="signinout-container">
      {!inProgress ? null : (isAuthenticated && <SignOutButton />) || <SignInButton />}
    </div>
  )
}

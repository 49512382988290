import { Button } from "@mui/material"
import { useMsal } from "@azure/msal-react"
import { formatNote, userCanEditOrDeleteNote } from "../../utils"
import { useEffect, useState } from "react"
import ConfirmationDialog from "./ConfirmationDialog"

export default function NoteEditView({ note, index, del }) {
  const [open, setOpen] = useState(false)
  const [deleteYN, setDeleteYN] = useState(false)
  const account = (useMsal()
    .instance.getActiveAccount()
    .username.match(/.+(?=@)/) || [""])[0]

  useEffect(() => {
    if (deleteYN) del(index, note)
  }, [deleteYN])

  return (
    <div className="note-container">
      {formatNote(note)}
      {userCanEditOrDeleteNote(account, note.CreatedBy) && note.hasOwnProperty("LastModifiedDate") && (
        <>
          <Button variant="contained" size="small" sx={{ height: "25px" }} color="error" onClick={() => setOpen(true)}>
            delete
          </Button>
          <ConfirmationDialog open={open} onClose={() => setOpen(false)} confirm={setDeleteYN} />
        </>
      )}
    </div>
  )
}

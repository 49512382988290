import { Typography } from "@mui/material"
import ClaimWitness from "./ClaimWitness"

export default function ClaimWitnessContainer({ witnesses, acquireToken }) {
  return (
    <div className="claim-witnesses-container">
      <Typography
        variant="h5"
        sx={{
          marginLeft: "2%",
          color: "black",
          border: "1px solid gray",
          borderRadius: "5px",
          backgroundColor: "white",
          maxWidth: "250px",
          padding: "5px"
        }}
      >
        Witnesses
      </Typography>
      {witnesses.map((witness) => (
        <ClaimWitness witness={witness} acquireToken={acquireToken} />
      ))}
    </div>
  )
}

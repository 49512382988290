import { Navigate } from "react-router-dom"
import { AuthenticatedTemplate } from "@azure/msal-react"
import "./Home.css"

export default function Home() {
  return (
    <div className="home-container">
      <AuthenticatedTemplate>
        <Navigate to="/dashboard" />
      </AuthenticatedTemplate>
      <h1>SAFETY</h1>
    </div>
  )
}

import PPE from "./PPE"
import "./PPE.css"

export default function PPEContainer({ ppeList }) {
  return (
    <div className="ppe-container">
      <h4>PPE list</h4>
      {ppeList.map((ppe) => (
        <PPE info={ppe} key={ppe.UUID} />
      ))}
    </div>
  )
}

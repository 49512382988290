import { useEffect } from "react"
import { useLocation } from "react-router-dom"

//--- Exactly what it sounds like, scrolls view to the top of the page on window location change ---//
export default function AutoScroller() {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }, [pathname])
  return
}

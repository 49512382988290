import "./Footer.css"

export default function Footer() {
  return (
    <footer className="footer">
      <h4 style={{ color: "white", marginLeft: "5%", marginRight: "50%", borderBottom: "1px solid white" }}>
        {new Date().getFullYear()} PCI
      </h4>
    </footer>
  )
}

import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { PrimaryReport } from "../PrimaryReport/PrimaryReport"
import { Avatar, Button, Paper, Typography } from "@mui/material"
import { prettyDate } from "../../utils"
import { red, grey } from "@mui/material/colors"
import ReactToPrint from "react-to-print"
import ClaimWitnessContainer from "../ClaimWitness/ClaimWitnessContainer"
import DeleteButton from "../DeleteButton/DeleteButton"
import Loading from "../Loading/Loading"
import axios from "axios"
import jsPDF from "jspdf"
import "./ClaimDetails.css"
import html2pdf from "html2pdf.js"

export default function ClaimDetails({ acquireToken, account }) {
  const [claim, setClaim] = useState(null)
  const [generateFileButtonMessage, setGenerateFileButtonMessage] = useState("Generate File Number")
  const [closeOpenActive, setCloseOpenActive] = useState(false)
  const [codeAllMessage, setCodeAllMessage] = useState("Send code all")
  const [printingActive, setPrintingActive] = useState(false)
  const [filingStringInputOpen, setFilingStringInputOpen] = useState(false)
  const [filingStringButtonmessage, setFilingStringButtonMessage] = useState("Set file number")
  const [newFilingString, setNewFilingString] = useState("")
  const [errorClass, setErrorClass] = useState("")
  const { claimUUID } = useParams()
  const reportRef = useRef()
  const navigate = useNavigate()

  const fetchClaim = async () => {
    if (!account) return
    const token = await acquireToken()
    const response = await axios
      .get(`api/get/${claimUUID}`, {
        headers: { ...axios.headers, Authorization: `Bearer ${token}` }
      })
      .catch((err) => navigate("/dashboard"))
    setTimeout(() => setClaim(response.data), 500)
  }

  const handleDelete = async () => {
    const token = await acquireToken()
    await axios.delete(`api/delete/${claimUUID}`, { headers: { ...axios.headers, Authorization: `Bearer ${token}` } })
    navigate("/dashboard")
  }

  const handleSendAllWitnessAccessCodes = async () => {
    if (codeAllMessage === "Sent") return
    setCodeAllMessage("...")
    const token = await acquireToken()
    await axios.post(
      `api/code/all/${claimUUID}`,
      {},
      { headers: { ...axios.headers, Authorization: `Bearer ${token}` } }
    )
    setCodeAllMessage("Sent")
  }

  const handleGenerateFileNumber = async () => {
    setGenerateFileButtonMessage("generating... ")
    const token = await acquireToken()
    const response = await axios.post(
      `api/generatefilenumber/${claimUUID}`,
      {},
      { headers: { ...axios.headers, Authorization: `Bearer ${token}` } }
    )
    if (response.status === 200) {
      setClaim(response.data)
      setGenerateFileButtonMessage("Generate File Number")
    }
  }

  const handleCloseClaim = async (flag) => {
    setCloseOpenActive(true)
    const token = await acquireToken()
    const response = await axios.post(
      `api/setstatuses/${claimUUID}`,
      { StatusClosed: flag },
      { headers: { ...axios.headers, Authorization: `Bearer ${token}` } }
    )
    if (response.status === 200) {
      fetchClaim()
    }
    setCloseOpenActive(false)
  }

  const handleDisplayFilingStringInput = () => {
    setFilingStringInputOpen((curr) => !curr)
    if (!filingStringInputOpen) {
      setFilingStringButtonMessage("Editing...")
    } else {
      setFilingStringButtonMessage("Set file number")
    }
    const inputBox = document.getElementById("modal-input")
    if (setFilingStringInputOpen) {
      setTimeout(() => inputBox.focus(), 10)
    }
  }

  const handleInputKeyDown = async (e) => {
    if (e.key === "Enter") {
      if (!/^[0-9]+$/.test(newFilingString)) {
        setErrorClass("shake")
        setTimeout(() => setErrorClass(""), 250)
        return
      }
      if (e.key === "Escape") {
        abortSetFilingString()
        return
      }
      const token = await acquireToken()
      const response = await axios
        .post(
          `api/setfilenumber/${claimUUID}`,
          { FilingNumber: newFilingString },
          { headers: { ...axios.headers, Authorization: `Bearer ${token}` } }
        )
        .catch((err) => {
          setNewFilingString("Something went wrong...")
          setTimeout(() => setNewFilingString(""), 1000)
        })
      if (response.status === 200) {
        await fetchClaim()
        abortSetFilingString()
      }
    }
  }

  const abortSetFilingString = () => {
    setNewFilingString("")
    setFilingStringInputOpen(false)
    setFilingStringButtonMessage("Set file number")
  }

  const handleDownloadPDF = () => {
    // const pdfDoc = new jsPDF({
    //   compress: true
    // })
    // setPrintingActive(true)
    // pdfDoc
    //   .html(reportRef.current, {
    //     async callback(pdfDoc) {
    //       await pdfDoc.save(`${claim.FilingNumber || "report"}`)
    //     }
    //   })
    //   .then(() => {
    //     setPrintingActive(false)
    //   })
    setPrintingActive(true)
    html2pdf()
      .from(reportRef.current)
      .set({
        filename: `${claim.FilingNumber || "report"}.pdf`,
        fit: true,
        image: { type: "jpeg", quality: 1 },
        html2canvas: { dpi: 300, letterRendering: true, width: 1000 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        pagebreak: { before: ".breaker", mode: "CSS", avoid: ".breaker" }
      })
      .save()
    setPrintingActive(false)
  }

  useEffect(() => {
    fetchClaim()
  }, [claimUUID, account])

  return (
    <>
      <div
        style={{
          opacity: filingStringInputOpen ? 0.5 : 1,
          pointerEvents: filingStringInputOpen ? "none" : "auto",
          filter: filingStringInputOpen ? "blur(5px)" : "none"
        }}
      >
        <div className="menu-container">
          <Button
            variant="contained"
            size="small"
            aria-label="small button group"
            sx={{ width: "75px" }}
            onClick={() => navigate("/dashboard")}
          >
            Return
          </Button>
          <Button
            variant="contained"
            size="small"
            aria-label="small button group"
            sx={{ width: "75px" }}
            onClick={() => navigate("/edit", { state: claimUUID })}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            size="small"
            aria-label="small button group"
            color={codeAllMessage === "Sent" ? "success" : codeAllMessage === "..." ? "warning" : "primary"}
            sx={{ width: "75px" }}
            onClick={handleSendAllWitnessAccessCodes}
          >
            {codeAllMessage}
          </Button>
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" size="small" aria-label="small button group" sx={{ width: "75px" }}>
                Print
              </Button>
            )}
            content={() => reportRef.current}
            onBeforeGetContent={async () => setPrintingActive(true)}
            onAfterPrint={() => setPrintingActive(false)}
          />
          <DeleteButton deleteReport={handleDelete} className="menu-button" />
        </div>
        <div className="details-container">
          {(claim && claim.PrimaryReport.GeneralInfo && claim.PrimaryReport.DetailedInfo && (
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", minHeight: "inherit" }}>
              <Paper
                elevation={3}
                sx={{ margin: "5%", padding: "2%", maxWidth: 500, bgcolor: claim.StatusClosed ? grey[600] : null }}
              >
                <Avatar sx={{ width: 70, bgcolor: red[500], marginBottom: "10px", fontSize: 12 }}>
                  {claim.ReportType}
                </Avatar>
                {(claim.StatusClosed && (
                  <>
                    <Typography>This claim is closed</Typography>
                    <Button variant="contained" color="warning" onClick={() => handleCloseClaim(false)}>
                      {closeOpenActive ? "Saving..." : "Re-open Claim"}
                    </Button>
                  </>
                )) || (
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ marginRight: "5px" }}
                    onClick={() => handleCloseClaim(true)}
                  >
                    {closeOpenActive ? "Saving..." : "Mark As Closed"}
                  </Button>
                )}
                {(claim.FilingString && (
                  <>
                    <Typography id="filingNo" variant="h5">
                      File Number: {claim.FilingString}
                    </Typography>
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={handleGenerateFileNumber}
                      sx={{ marginRight: "5px" }}
                    >
                      re-assign file#
                    </Button>
                    <Button
                      variant="contained"
                      color={filingStringInputOpen ? "success" : "error"}
                      onClick={handleDisplayFilingStringInput}
                    >
                      {filingStringButtonmessage}
                    </Button>
                  </>
                )) ||
                  (!claim.StatusClosed && (
                    <Button variant="contained" color="success" onClick={handleGenerateFileNumber}>
                      {generateFileButtonMessage}
                    </Button>
                  ))}
                <h2>
                  {claim.ReportType.replace(/(?<=[a-z])([A-Z])/g, " $1")} report filed by{" "}
                  {claim.PrimaryReport.GeneralInfo.Reporter.Name} on {prettyDate(claim.IncidentDateTime)}
                </h2>
              </Paper>
              <Typography
                variant="h5"
                sx={{
                  marginLeft: "2%",
                  color: "black",
                  border: "1px solid gray",
                  borderRadius: "5px",
                  backgroundColor: "white",
                  maxWidth: "250px",
                  padding: "5px"
                }}
              >
                Primary Report
              </Typography>
              <PrimaryReport
                claimUUID={claimUUID}
                report={claim.PrimaryReport}
                reports={claim.AdditionalReports}
                notes={claim.Comments}
                printing={printingActive}
                ref={reportRef}
              />
              <Button
                variant="contained"
                color="success"
                size="small"
                aria-label="small button group"
                sx={{ width: "150px", margin: "10px" }}
                onClick={handleDownloadPDF}
              >
                Download PDF
              </Button>
              {claim.AdditionalReports.map((report) => {
                return <PrimaryReport notes={claim.Comments} report={report} reports={claim.AdditionalReports} />
              })}
              {claim.Witnesses.length > 0 && (
                <ClaimWitnessContainer witnesses={claim.Witnesses} acquireToken={acquireToken} />
              )}
            </div>
          )) || <Loading />}
        </div>
      </div>
      <input
        type="text"
        id="modal-input"
        className={errorClass}
        value={newFilingString}
        tabIndex={0}
        style={{
          visibility: filingStringInputOpen ? "visible" : "collapse",
          position: "absolute",
          top: "40%",
          left: "10%",
          opacity: 1,
          height: "50px",
          width: "175px",
          fontSize: "24px",
          border: "2px solid darkRed",
          textAlign: "center",
          borderRadius: "5px"
        }}
        onChange={({ target }) => setNewFilingString(target.value)}
        onKeyDown={handleInputKeyDown}
        onBlur={abortSetFilingString}
      />
    </>
  )
}

import SignInSignOutButton from "../SignInSignOutButton/SignInSignOutButton"
import "./Header.css"

export default function Header() {
  return (
    <div className="header-container">
      <SignInSignOutButton />
    </div>
  )
}

import { UnauthenticatedTemplate } from "@azure/msal-react"
import { Navigate } from "react-router-dom"

//--- Component to embed into a page to automatically kick out users who are not logged in ---//
export default function Redirect() {
  return (
    <UnauthenticatedTemplate>
      <Navigate to="/" />
    </UnauthenticatedTemplate>
  )
}

import { useEffect, useState } from "react"
import Photo from "./Photo"
import { getOrientation } from "../../../utils"

export default function PhotoContainer({ photos, printing }) {
  const [orientation, setOrientation] = useState(getOrientation())

  const updateOrientation = () => {
    setOrientation(getOrientation())
  }

  useEffect(() => {
    window.addEventListener("orientationchange", updateOrientation)
    return () => {
      window.removeEventListener("orientationchange", updateOrientation)
    }
  }, [])

  return (
    <div className="photo-container">
      <h4>Photos</h4>
      {(photos.length &&
        photos.map((photo) => {
          return <Photo info={photo} orientation={orientation} printing key={photo.UUID} />
        })) || <h6>N/A</h6>}
    </div>
  )
}

import { Button, DialogTitle, List, ListItem, ListItemButton } from "@mui/material"
import Dialog from "@mui/material/Dialog"

export default function ConfirmationDialog({ open, onClose, confirm }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Are you sure you want to delete this note? (this cannot be undone)</DialogTitle>
      <List>
        <ListItem>
          <ListItemButton
            sx={{ color: "text.primary" }}
            onClick={() => {
              onClose()
              confirm(true)
            }}
          >
            Yes
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            sx={{ color: "text.primary" }}
            onClick={() => {
              onClose()
              confirm(false)
            }}
          >
            No
          </ListItemButton>
        </ListItem>
        <Button
          content="No"
          onClick={() => {
            onClose()
            confirm(false)
          }}
        />
      </List>
    </Dialog>
  )
}

import { useState } from "react"
import "./Photo.css"

export default function Photo({ info, printing }) {
  const [imageView, setImageView] = useState(printing ? "full-view" : "thumbnail")

  return (
    <>
      <div className="breaker">
        <div
          className={imageView}
          style={{
            backgroundImage: `url(${info.Base64})`,
            backgroundSize: "contain",
            pageBreakBefore: printing ? "always" : "auto",
            pageBreakInside: printing ? "avoid" : "auto",
            marginTop: printing ? "5%" : 0
          }}
          onClick={() => setImageView((curr) => (curr === "thumbnail" ? "full-view" : "thumbnail"))}
        ></div>
        <p>{info.Description}</p>
      </div>
    </>
  )
}

import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useMsal } from "@azure/msal-react"
import { InputLabel, NativeSelect, TextField } from "@mui/material"
import Claim from "../../Components/Claim/Claim"
import Redirect from "../../Components/Redirect"
import Loading from "../../Components/Loading/Loading"
import Empty from "../../Components/Empty/Empty"
import axios from "axios"
import "./Dashboard.css"

export default function Dashboard({ acquireToken }) {
  //--- State and global variables ---//
  const [claims, setClaims] = useState(null)
  const [claimYear, setClaimYear] = useState(new Date().getFullYear().toString())
  const [claimType, setClaimType] = useState("All")
  const [searchInfo, setSearchInfo] = useState("")
  const { instance } = useMsal()
  const account = instance.getActiveAccount()
  const navigate = useNavigate()

  //--- Handlers & helper functions ---//
  const verifyUserHasAccess = async () => {
    const token = await acquireToken()
    const result = await axios.get("api/canaccess", { headers: { ...axios.headers, Authorization: `Bearer ${token}` } })
    if (!result.data) navigate("/unauthorized")
  }

  //--- Check if the user exists and has the correct access level for the application ---//
  if (!verifyUserHasAccess()) navigate("/unauthorized")

  const populateClaims = async () => {
    const token = await acquireToken()
    const response = await axios.get(`api/year/${claimYear}`, {
      headers: { ...axios.headers, Authorization: `Bearer ${token}` }
    })
    if (response.data.code === "ERR_BAD_REQUEST") return
    const filtered = response.data
      .filter(
        (e) =>
          (claimType === "All" || e.ReportType.includes(claimType)) &&
          (e.JobNumber.toLowerCase().includes(searchInfo) ||
            e.ClaimantName.toLowerCase().includes(searchInfo) ||
            e.IncidentDescription.toLowerCase().includes(searchInfo))
      )
      .sort((a, b) => new Date(b.CompletionDate) - new Date(a.CompletionDate))
    setTimeout(() => setClaims(filtered), 500)
  }

  const handleYearChange = (year) => setClaimYear(year)

  const handleTypeChange = (type) => setClaimType(type)

  useEffect(() => {
    if (account) {
      verifyUserHasAccess()
      populateClaims()
    }
  }, [claimYear, claimType, searchInfo])

  return (
    <div className="dashboard-container">
      <Redirect />
      <div className="claims-container">
        <div className="options-container">
          <div className="selector-container">
            <InputLabel variant="standard" htmlFor="year-selector" style={{ color: "white" }}>
              Year
            </InputLabel>
            <NativeSelect
              inputProps={{ id: "year-selector", name: "year" }}
              className="selector"
              value={claimYear}
              onChange={({ target }) => handleYearChange(target.value)}
            >
              {/* Auto-populate year options based on current year */}
              {Array.from({ length: new Date().getFullYear() - 2020 }, (_, i) => i + 2021)
                .reverse()
                .map((year) => (
                  <option value={year}>{year}</option>
                ))}
            </NativeSelect>
          </div>
          <div className="selector-container">
            <InputLabel htmlFor="year-selector" style={{ color: "white" }}>
              Type
            </InputLabel>
            <NativeSelect
              inputProps={{ id: "type-selector", name: "type" }}
              className="selector"
              value={claimType}
              onChange={({ target }) => handleTypeChange(target.value)}
            >
              <option value="All">All</option>
              <option value="Utility">Utility</option>
              <option value="Injury">Injury</option>
              <option value="Employee">Discipline</option>
              <option value="Other">Other</option>
            </NativeSelect>
          </div>
          <div className="options-container">
            <TextField
              label="Search"
              variant="standard"
              sx={{ bgcolor: "white", borderRadius: "5px" }}
              value={searchInfo}
              onChange={({ target }) => setSearchInfo(target.value.toLowerCase().trimStart())}
            />
          </div>
        </div>
        {/* Conditionally render a message based on the value of the claims variable */}
        {claims === null ? (
          <Loading />
        ) : claims.length > 0 ? (
          claims.map((c) => <Claim claim={c} key={c.UUID} />)
        ) : (
          <Empty />
        )}
      </div>
    </div>
  )
}

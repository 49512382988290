import { BrowserRouter, Route, Routes } from "react-router-dom"
import { useMsal } from "@azure/msal-react"
import Header from "./Components/Header/Header"
import Footer from "./Components/Footer/Footer"
import Home from "./Pages/Home/Home"
import Dashboard from "./Pages/Dashboard/Dashboard"
import ClaimDetails from "./Components/ClaimDetails/ClaimDetails"
import WitnessReportDetails from "./Components/WitnessReportDetails/WitnessReportDetails"
import EditClaimForm from "./Components/EditClaimForm/EditClaimForm"
import Unauthorized from "./Pages/Unauthorized/Unauthorized"
import { loginRequest } from "./msalConfig"
import "./App.css"
import "./MainContainer.css"
import AutoScroller from "./Components/AutoScroller"
import EditNotesForm from "./Components/EditNotesForm/EditNotesForm"

export const DEBUG = process.env.REACT_APP_BASE_SERVICE_URL.includes("test")

export default function App() {
  const { instance } = useMsal()
  const account = instance.getActiveAccount()

  const acquireToken = async () => {
    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      account: account || instance.getActiveAccount()
    })
    return response.accessToken
  }

  return (
    <BrowserRouter>
      <AutoScroller />
      <div className="main-container">
        <Header account={account} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={<Dashboard acquireToken={acquireToken} />} />
          <Route path=":claimUUID" element={<ClaimDetails acquireToken={acquireToken} account={account} />} />
          <Route path="/witnessreportdetails" element={<WitnessReportDetails acquireToken={acquireToken} />} />
          <Route path="/edit" element={<EditClaimForm acquireToken={acquireToken} />} />
          <Route path="edit-notes" element={<EditNotesForm acquireToken={acquireToken} />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  )
}

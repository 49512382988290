import { Button } from "@mui/material"
import { formatNote } from "../../utils"
import "./Notes.css"
import { useNavigate } from "react-router-dom"

export default function Notes({ notes, claimUUID, printing }) {
  const navigate = useNavigate()
  const handleEditNotes = () => {
    navigate("/edit-notes", { state: { notes: notes, uuid: claimUUID } })
  }

  return (
    <div className="notes-container">
      {!printing && <h5>Safety Team Notes</h5>}
      {!printing && (
        <Button
          variant="contained"
          size="small"
          maxLength={1024}
          aria-label="small button group"
          color="primary"
          sx={{ width: "75px" }}
          onClick={handleEditNotes}
        >
          Edit Notes
        </Button>
      )}
      {!printing && notes && notes.map((note) => formatNote(note))}
    </div>
  )
}

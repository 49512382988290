import { useEffect, useState } from "react"

export default function DatePicker({ set, dateTime }) {
  const [date, setDate] = useState(new Date(dateTime).toISOString().split("T")[0])
  const [time, setTime] = useState(new Date(dateTime).toTimeString().slice(0, 5))

  const updateDateTime = () => {
    const newDate = new Date(date + "T" + time)
    newDate.setHours(newDate.getHours() - newDate.getTimezoneOffset() / 60)
    set((curr) => ({
      ...curr,
      IncidentDatetime: newDate
    }))
  }

  useEffect(() => {
    updateDateTime()
  }, [date])

  return (
    <div
      style={{
        display: "flex",
        gap: "2em"
      }}
    >
      <div>
        <label htmlFor="reportDate" style={{ color: "white" }}>
          Claim date:&nbsp;
        </label>
        <input
          type="date"
          id="reportDate"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          max={new Date().toISOString().split("T")[0]}
        />
      </div>
      <div>
        <label htmlFor="reportTime" style={{ color: "white" }}>
          Time of claim:&nbsp;
        </label>
        <input type="time" id="reportTime" value={time} onChange={(e) => setTime(e.target.value)} />
      </div>
    </div>
  )
}

import { useMsal } from "@azure/msal-react"
import { loginRequest } from "../../../msalConfig"
import "./SignInButton.css"

export default function SignInButton() {
  const { instance } = useMsal()

  const handleLogin = () => {
    instance.loginRedirect({ ...loginRequest }).catch((err) => console.error(err))
  }
  return (
    <div>
      <button className="signin-button" onClick={handleLogin}>
        Sign In
      </button>
    </div>
  )
}

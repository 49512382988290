import { forwardRef } from "react"
import SafetyPayInfo from "./SafetyPayInfo/SafetyPayInfo"
import GeneralInfo from "./GeneralInfo/GeneralInfo"
import DetailedInfo from "./DetailedInfo/DetailedInfo"
import InjuryInfoSignature from "./InjuryInfoSignature/InjuryInfoSignature"
import PhotoContainer from "./Photos/PhotoContainer"
import HospitalInfo from "./HospitalInfo/HospitalInfo"
import PoliceInfo from "./PoliceInfo/PoliceInfo"
import WitnessContainer from "./Witness/WitnessContainer"
import PPEContainer from "./PPEInfo/PPEContainer"
import Notes from "../Notes/Notes"
import "./PrimaryReport.css"

export const PrimaryReport = forwardRef(({ claimUUID, report, reports, notes = [], printing }, ref) => {
  return (
    <div className="primary-report-container" ref={ref}>
      <SafetyPayInfo />
      {report.ReportType !== "WitnessReport" && <Notes notes={notes} claimUUID={claimUUID} printing={printing} />}
      {report.GeneralInfo && <GeneralInfo section={report.GeneralInfo} />}
      {report.DetailedInfo && <DetailedInfo section={report.DetailedInfo} />}
      {report.WitnessInfos && report.WitnessInfos.length > 0 && (
        <WitnessContainer witnesses={report.WitnessInfos} list={reports} />
      )}
      {report.Photos && report.Photos.length > 0 && <PhotoContainer photos={report.Photos} printing />}
      {report.HospitalInfo && report.HospitalInfo.HospitalName && <HospitalInfo section={report.HospitalInfo} />}
      {report.PoliceInfo &&
        (report.PoliceInfo.CitationNumber ||
          report.PoliceInfo.RespondingDepartment ||
          report.PoliceInfo.ReportNumber) && <PoliceInfo section={report.PoliceInfo} />}
      {report.InjuryAcknowledgementInfo && (
        <InjuryInfoSignature
          section={report.InjuryAcknowledgementInfo}
          employee={report.GeneralInfo.AffectedEmployee.Name}
        />
      )}
      {report.PPEInfos && report.PPEInfos.length > 0 ? <PPEContainer ppeList={report.PPEInfos} /> : null}
    </div>
  )
})

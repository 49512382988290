export default function PPE({ info }) {
  return (
    <div className="ppe">
      <table>
        <tbody>
          <tr>
            <td>Type: </td>
            <td>{info.Type}</td>
          </tr>
          <tr>
            <td>Reason: </td>
            <td>{info.MissingFromPerson ? "Missing" : "Used imporoperly"}</td>
          </tr>
          <tr>
            <td>Notes: </td>
            <td>{info.Comments}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
